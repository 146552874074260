


.tabbuttons {
  background-color: #e4e4e499;
  width: 900px;
  justify-content: space-around;
  border-radius: 5px;
}

.wt {
  color: white;
}

.bt {
  color: black;
}


.sec2{

  background-color: transparent;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.sec2heading {
  padding-top: 80px;
}

.formw {
  width: 410px;
  height: 44px;
  border-radius: 10px;
}

.heading {
  font-weight: 700;
  font-size: 46px;
  color: white;
  line-height: 55px;
}

.wheading {
  font-size: 36px;
  font-weight: 700;
  top: 43px;
  left: 0px;
  width: 429px;
  height: 45px;
  color: white;
  padding-top: 120px;
}

.header1 {
  background-color: transparent;
}
.logo-light{
  max-height: 60px;
}

.bfont {
  size: 22px;
  font-weight: 400;
  /* text-align: ; */
  font: normal normal normal 22px/31px Lexend Deca;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.card {
  width: 50vh;
}
.neonbg {
  /* background: linear-gradient(135deg, #222222, #333333, #444444, #555555); */
  /* Create a wine red to black gradient */
  /* background: linear-gradient(to top, #470101, #000000); */
  background: linear-gradient(90deg, #00a0c2, #0065c1);
}
.cardtext {
  color: #cccccc;
}

.navbar {
  padding-top: 9.9px;
}

.text1 {
  width: 330px;
  font-weight: 400;
  font: normal normal normal 22px/31px Lexend Deca;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.tbbtn {
  font-size: xx-large;
}

.tabimg {
  padding-left: 101px;
}

.video {
  height: 509px;
  width: 904px;
  border-radius: 20px;
}
.video video {
  border-radius: 10px;
}

.uk-subnav-pill > .uk-active > a {
  color: black;
  border-radius: 18px;
  background-color: white;
}
.uk-subnav-pill > * > :first-child {
  padding: 7px 42px;
}

.flabels {
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin-bottom: 1px;
  margin-top: 44px;
}

.fbutton {
  margin-top: 12%;
  margin-left: 45%;
  background-color: "#02EF93";
  border-radius: 3px;
  color: #00a0c2;
  border-color: transparent;
  font-weight: 700;
  font-size: 2vw;
  transition: background-color 0.5s;
  padding: 10px;
  font-family: revert;
}
.fbutton:hover {
  color: #ffffff;
  background-color: #00a0c2;
}

.underline-input {
  border: none;
  border-bottom: 8px solid black;
  outline: none;
  padding: 5px;
}

.tabtext2 {
  margin-top: 100px;
}

.wheading2 {
  width: 536px;
  height: 144px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 57px/68px Lexend Deca;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.sociallogo {
  /* padding-left: 107px; */
  padding: 7%;
}

.text2 {
  width: 352px;
  height: 87;
}
a,
.uk-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.whpd {
  padding-right: 100vh;
}

.formgrid {
  height: "483px";
  width: "904px";
  border-radius: "20px";
}

.formtabbutton {
  background-color: transparent;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: Lexend Deca;
  font-size: 24px;
}

.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: black;
  font-weight: 500;
}
.countheads{
  margin-left: 17vh;
}

.mobheader{
  width: 100%;
  height: 5vh;
  background-color: #ffffff;

}
.hanim1{
  /* margin-top: 40vh; */
}


.logowrap{
  padding: 5px;
  height: 20%;
  width: 20%;
}

  .listwrap li {
    padding: 0px  4px  4px  4px ;
    /* font-family:Arial, Helvetica, sans-serif */
    
  }
.listwrap ul {
  
  list-style: none ;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 10px;
}
.listwrap li a {
  
 color: #000000;
}

.first_sec{
  margin-top: -100px;
  height: 100vh;
}
.first_sec img {

  z-index: 1;
  object-position: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.first_sec_content{
  z-index: 3;
}

.overlay{
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(45deg, #000000, #312f2f00);
}

.service_sec{
  text-align: center;
  padding: 100px 40px;
  background: rgb(2,0,36);
background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,102,121,1) 61%, rgba(0,34,68,1) 100%);
}
.service_head{
  padding: 20PX;
}
.service_head h1{
  color: white;
  font-weight: 800px;
}
.service_head h5{
  color:#00a0c2;
  font-weight: 600;
}
.service_sec .uk-card-primary{
  background-color: #011936;
  border-radius: 12px;
}
.service_sec img:hover{
  animation:bounce;
  animation-duration: 2s;
}

.service_sec .uk-card-primary h2 {
  padding: 30px 0 0 0  ;
}
.service_sec .uk-card-primary a{
  color:#00a0c2;
  font-weight: 600;
}

.headingwrap{
    margin-top: 20%;
    height: 100%;
}


.countsec{
  padding: 150px 100px;
  background-image: url("../../bg1.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  z-index: 1;
}
.overlay2{
  position: absolute;
    /* z-index: 2; */
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(45deg, #000000, #312f2f00);
    top: 0;
    left: 0;
}
.countwrap{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.countwrap span {
  color: aliceblue;
  font-size: 3vw;
}
.countwrap p {
  color: aliceblue;
  font-size: 1.5em;
}
.countwrap img {
  display: block;
  width: 30%;
  margin-left: 35%;
}


.second_sec{
  background-color: #000a12;
  height: 100vh;
}
.second_sec_wrapper{
  padding: 100px;
}
.second_sec ul {
  list-style:none;
  padding-left: 0px !important;
  margin-top: 60px;
}

.second_sec ul h1 ,p {
  /* width: 80%; */
}

.second_sec ul svg {
  margin-right: 20px;
  fill: #00a0c2;
}

.cntctform{
  background: #000a12;
}


.ls_sec{
  height: 100vh;
  background-color: #000a12;
  
}

.newsletter{
  border-radius: 12px;
  height: fit-content;
  width: 90%;
  background: rgb(2,24,22);
  text-align: center;
  background: linear-gradient(90deg, rgba(2,24,22,1) 0%, rgba(2,13,31,1) 45%, rgba(3,24,25,1) 100%);
  margin-left: auto;
  margin-right: auto;
}
.newsletteritem{
  padding: 8%;
}
.newsletteritem .uk-button-primary{
  display:inline;
  /* padding: 4%; */
}

.uk-list li {
  display: flex;
  align-items: end;
}

.round{
  background: #0e2237;
  width: 70px;
  height: 70px;
  border-radius: 40px;
  margin: 5px 15px;
}

.uk-list strong{
  color: #00a0c2;
  
}

.undragable {
  -webkit-user-drag: none !important;
}

@media (max-width : 500px) {
  .hanim1{
    font-size: 20vw !important;
    margin-top: 32vh !important;
  }
  #bottombtn{
    font-size: 7vw !important;
        margin-top: 38vh !important;
        margin-left: 0 !important;
  }
  .anip{
    top: 8% !important;
    padding: 1.5em 3.5em !important;
  }
  .anis{
    font: 700 3em / 1 "Oswald", sans-serif !important;
  }
  .second_sec_wrapper {
    padding: 30px;
  }

  
}